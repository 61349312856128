import React from "react"
import {WrappedFieldProps} from "redux-form"
import {
    DescriptionProps,
    OptionItem,
    SelectComponentProps,
} from "../../ui/SelectComponent/interfaces"
import {SelectComponent} from "../../ui/SelectComponent/SelectComponentLazy"
import {CommonFieldProps} from "redux-form/lib/Field"

interface OptionTypeBase {
    [key: string]: any
}
type OptionsType<OptionType extends OptionTypeBase> = ReadonlyArray<OptionType>

export type ValueType<OptionType extends OptionTypeBase, IsMulti extends boolean> =
    | (IsMulti extends true ? OptionsType<OptionType> : OptionType)
    | null
    | undefined

export interface InputSelectProps
    extends WrappedFieldProps,
        DescriptionProps,
        Omit<SelectComponentProps, "onChange">,
        Pick<CommonFieldProps, "onChange"> {}

export const FormSelect: React.FC<InputSelectProps> = (props) => {
    const {
        input,
        meta: {error, touched},
        ...selectProps
    } = props

    return (
        <SelectComponent
            {...input}
            error={touched ? error : undefined}
            onBlur={() => input.onBlur(input.value)}
            {...selectProps}
            onChange={(value: ValueType<OptionItem, false>) => input.onChange(value)}
        />
    )
}
