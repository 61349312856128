import {i18n} from "../i18n/i18n"
import {
    IUserAppSettings,
    IUserNotificationsGroup,
    IUserSecurity,
    UserAppSettings,
    UserNotifications,
    UserSecurity,
} from "./settingsTypes"

export enum DateFormat {
    DayFirst = "DAY_BEFORE_MONTH",
    MonthFirst = "MONTH_BEFORE_DAY",
}

export enum TimeFormat {
    Hour12 = "HOUR_12",
    Hour24 = "HOUR_24",
}

export interface IUserSettingsAndNotifications {
    appSettings: IUserAppSettings
    notifications: IUserNotificationsGroup[]
    security: IUserSecurity
    microsoftSSO: ProfileMicrosoftSSO
    dateFormat: DateFormat
    timeFormat: TimeFormat
}

export type ProfileMicrosoftSSO = {
    enable: boolean
    loginURL?: string
    allowCreateIntegration: boolean
    integrationExists: boolean
    linked: boolean
}

export const getUserSettingsLabels = () => ({
    [UserAppSettings.AutoOpenDownloadWindow]: i18n.t(
        "person:app_settings.fieldname.enable_download_window"
    ),
})

export const getUserSecurityLabels = () => ({
    [UserSecurity.TFA]: i18n.t("person:security.fieldname.enable_two_factor"),
})

export const getUserNotificationsLabels = (): {[type in UserNotifications]: string} => ({
    // advert notifications
    [UserNotifications.AD_EXPIRE_SOON]: i18n.t("notifications:advert.content_expire_soon"),
    [UserNotifications.AD_THREE_MONTHS_UNCHANGED]: i18n.t(
        "notifications:advert.content_unchanged_3months"
    ),
    [UserNotifications.AD_APPROVED]: i18n.t("notifications:advert.content_accepted"),
    [UserNotifications.AD_REJECTED]: i18n.t("notifications:advert.content_rejected"),
    [UserNotifications.AD_EXPIRED]: i18n.t("notifications:advert.content_expired"),
    [UserNotifications.PARTNER_CONTENT_APPROVAL]: i18n.t("notifications:advert.content_pending"),
    [UserNotifications.MARKET_ACCESS_CHANGED]: i18n.t(
        "notifications:advert.markets_accessible_changed"
    ),
    // content notifications
    [UserNotifications.SCHEDULED_CONTENT_OFFLINE]: i18n.t("notifications:content.content_expired"),
    [UserNotifications.STUDENT_CONTENT_APPROVAL]: i18n.t(
        "notifications:content.student_content_pending"
    ),
    [UserNotifications.STUDENT_PARTNER_CONTENT_APPROVAL]: i18n.t(
        "notifications:content.partner_content_pending"
    ),
    [UserNotifications.STUDENT_GLOBAL_CONTENT_APPROVAL]: i18n.t(
        "notifications:content.global_content_pending"
    ),
    [UserNotifications.PROVIDER_CONTENT_APPROVAL]: i18n.t(
        "notifications:content.provider_content_pending"
    ),
    [UserNotifications.CONTENT_EXPIRE_SOON]: i18n.t("notifications:content.content_expire_soon"),

    // devices notifications
    [UserNotifications.DEVICE_OFFLINE]: i18n.t("notifications:device.device_offline"),
    [UserNotifications.SCREEN_OFFLINE]: i18n.t("notifications:device.device_offline"),
    [UserNotifications.SCREEN_CREATED]: i18n.t("notifications:device.screen_created"),
    [UserNotifications.SCREEN_BROADCASTING_ENDED]: i18n.t("notifications:device.broadcast_paused"),
    [UserNotifications.ALERT_ONLINE]: i18n.t("notifications:device.alert_enabled_on_screen"),
    [UserNotifications.ALERT_OFFLINE]: i18n.t("notifications:device.alert_disabled_on_screen"),
    [UserNotifications.LIVE_FEED_ONLINE]: i18n.t("notifications:device.live_feed_on_screen"),
    [UserNotifications.RTC_FEED_ONLINE]: i18n.t("notifications:device.stream_on_screen"),
    [UserNotifications.ANNOUNCEMENT_STARTED]: i18n.t("notifications:device.announcement_started"),
    [UserNotifications.DEVICE_TRAIL_PERIOD_EXPIRED_ADMIN]: i18n.t(
        "notifications:device.trial_period_expired"
    ),
    [UserNotifications.DEVICE_TRIAL_PERIOD_STARTED_ADMIN]: i18n.t(
        "notifications:device.trial_period_started"
    ),
    [UserNotifications.DEVICE_TRIAL_PERIOD_EXPIRES_SOON]: i18n.t(
        "notifications:device.trial_period_expires"
    ),
    [UserNotifications.HIGHER_ED_DEVICE_TRIAL_PERIOD_EXPIRES_SOON]: i18n.t(
        "notifications:device.trial_period_expires"
    ),
    [UserNotifications.BUSINESS_DEVICE_TRIAL_PERIOD_EXPIRES_SOON]: i18n.t(
        "notifications:device.trial_period_expires"
    ),
    [UserNotifications.DEVICE_TRIAL_PERIOD_EXPIRED]: i18n.t(
        "notifications:device.trial_period_expired"
    ),
    [UserNotifications.HIGHER_ED_DEVICE_TRIAL_PERIOD_EXPIRED]: i18n.t(
        "notifications:device.trial_period_expired"
    ),
    [UserNotifications.BUSINESS_DEVICE_TRIAL_PERIOD_EXPIRED]: i18n.t(
        "notifications:device.trial_period_expired"
    ),
    [UserNotifications.DEVICE_TRIAL_14]: i18n.t("notifications:device.trial_period_expires_2weeks"),
    [UserNotifications.DEVICE_TRIAL_7]: i18n.t("notifications:device.trial_period_expires_1week"),
    [UserNotifications.DEVICE_TRIAL_3]: i18n.t("notifications:device.trial_period_expires_3days"),
    [UserNotifications.DEVICE_TRIAL_1]: i18n.t(
        "notifications:device.trial_period_expires_tomorrow"
    ),

    // projects notifications
    [UserNotifications.GLOBAL_PROJECT_CREATED]: i18n.t(
        "notifications:project.global_project_created"
    ),
    [UserNotifications.PARTNER_PROJECT_CREATED]: i18n.t(
        "notifications:project.partner_project_created"
    ),
    [UserNotifications.PROJECT_AVAILABLE]: i18n.t("notifications:project.project_available"),
    [UserNotifications.STUDENT_LOCAL_PROJECT_CREATED]: i18n.t(
        "notifications:project.project_created"
    ),
    [UserNotifications.STUDENT_GLOBAL_PROJECT_CREATED]: i18n.t(
        "notifications:project.global_project_created"
    ),
    [UserNotifications.STUDENT_GLOBAL_CONTENT_APPROVED]: i18n.t(
        "notifications:project.global_content_approved"
    ),
    [UserNotifications.STUDENT_SPONSOR_CONTENT_APPROVED]: i18n.t(
        "notifications:project.partner_content_approved"
    ),
    [UserNotifications.STUDENT_SPONSOR_PROJECT_CREATED]: i18n.t(
        "notifications:project.partner_project_created"
    ),
    [UserNotifications.CONTENT_APPROVED]: i18n.t("notifications:project.student_content_approved"),
    [UserNotifications.CONTENT_REJECTED]: i18n.t("notifications:project.student_content_rejected"),
    [UserNotifications.SCHOOL_USE_CONTENT]: i18n.t("notifications:project.student_content_copied"),
    [UserNotifications.PARTNER_USE_CONTENT]: i18n.t(
        "notifications:project.student_content_copied_by_partner"
    ),
    [UserNotifications.PROJECT_SHARED]: i18n.t("notifications:project.project_shared"),

    // libraries notifications
    [UserNotifications.LOCAL_LIBRARY_CREATED]: i18n.t(
        "notifications:library.local_library_created"
    ),
    [UserNotifications.GLOBAL_LIBRARY_ENABLE]: i18n.t(
        "notifications:library.global_library_available"
    ),
    [UserNotifications.PREMIUM_LIBRARY_ENABLE]: i18n.t(
        "notifications:library.premium_library_available"
    ),

    // provider notifications
    [UserNotifications.PROVIDER_LOCAL_LIBRARY_ENABLE]: i18n.t(
        "notifications:provider.local_library_available"
    ),
    [UserNotifications.PROVIDER_GLOBAL_LIBRARY_ENABLE]: i18n.t(
        "notifications:provider.global_library_available"
    ),
    [UserNotifications.PROVIDER_CONTENT_APPROVED]: i18n.t(
        "notifications:provider.content_approved"
    ),
    [UserNotifications.PROVIDER_CONTENT_REJECTED]: i18n.t(
        "notifications:provider.content_rejected"
    ),
    [UserNotifications.PROVIDER_PREMIUM_LIBRARY_ENABLE]: i18n.t(
        "notifications:provider.premium_library_available"
    ),
    [UserNotifications.PREMIUM_LIBRARY_VERSION_APPROVAL]: i18n.t(
        "notifications:provider.version_information_pending"
    ),
    [UserNotifications.PREMIUM_LIBRARY_VERSION_APPROVED]: i18n.t(
        "notifications:provider.version_information_approved"
    ),
    [UserNotifications.PREMIUM_LIBRARY_VERSION_REJECTED]: i18n.t(
        "notifications:provider.version_information_rejected"
    ),
    [UserNotifications.PREMIUM_LIBRARY_VERSION_IS_ACTIVE]: i18n.t(
        "notifications:provider.version_information_active"
    ),

    // newLine notifications
    [UserNotifications.NEWLINE_SCHOOL_TO_REAL]: i18n.t(
        "notifications:newline.partner_content_expire_soon"
    ),
    [UserNotifications.NEWLINE_SCHOOL_CREATED]: i18n.t(
        "notifications:newline.trial_school_created"
    ),
    [UserNotifications.CUSTOMER_DISTRICT_CREATED]: i18n.t("notifications:newline.district_created"),
    [UserNotifications.CUSTOMER_SCHOOL_CREATED]: i18n.t("notifications:newline.school_created"),
    [UserNotifications.CUSTOMER_BUILDING_CREATED]: i18n.t("notifications:newline.building_created"),

    [UserNotifications.CUSTOMER_HIGHER_ED_DISTRICT_CREATED]: i18n.t(
        "notifications:newline.higher_ed_location_created"
    ),
    [UserNotifications.CUSTOMER_HIGHER_ED_BUILDING_CREATED]: i18n.t(
        "notifications:newline.higher_ed_building_created"
    ),

    // support request notifications
    [UserNotifications.SUPPORT_REQUEST_RESPONSE]: i18n.t(
        "notifications:support_request.support_responded_request"
    ),
    [UserNotifications.BUSINESS_SUPPORT_REQUEST_RESPONSE]: i18n.t(
        "notifications:support_request.support_responded_request"
    ),
    [UserNotifications.HIGHER_ED_SUPPORT_REQUEST_RESPONSE]: i18n.t(
        "notifications:support_request.support_responded_request"
    ),
    [UserNotifications.SPONSOR_SUPPORT_REQUEST_RESPONSE]: i18n.t(
        "notifications:support_request.support_responded_request"
    ),
    [UserNotifications.PROVIDER_SUPPORT_REQUEST_RESPONSE]: i18n.t(
        "notifications:support_request.support_responded_request"
    ),
    [UserNotifications.SUPPORT_REQUEST_CREATED]: i18n.t(
        "notifications:support_request.support_request_created"
    ),
    [UserNotifications.SUPPORT_REQUEST_NEW_SUPPORT]: i18n.t(
        "notifications:support_request.support_request_assigned"
    ),

    // business notifications
    [UserNotifications.BUSINESS_CONTENT_EXPIRE_SOON]: i18n.t(
        "notifications:business.content_expire_soon"
    ),
    [UserNotifications.SCHEDULED_BUSINESS_CONTENT_OFFLINE]: i18n.t(
        "notifications:business.scheduled_content_expired"
    ),
    [UserNotifications.BUSINESS_SCREEN_CREATED]: i18n.t("notifications:business.screen_created"),
    [UserNotifications.BUSINESS_SCREEN_BROADCASTING_ENDED]: i18n.t(
        "notifications:business.broadcast_paused"
    ),
    [UserNotifications.BUSINESS_ALERT_ONLINE]: i18n.t(
        "notifications:business.alert_enabled_on_screen"
    ),
    [UserNotifications.BUSINESS_ALERT_OFFLINE]: i18n.t(
        "notifications:business.alert_disabled_on_screen"
    ),
    [UserNotifications.BUSINESS_LIVE_FEED_ONLINE]: i18n.t(
        "notifications:business.live_feed_on_screen"
    ),
    [UserNotifications.BUSINESS_RTC_FEED_ONLINE]: i18n.t("notifications:business.stream_on_screen"),
    [UserNotifications.BUSINESS_SCREEN_OFFLINE]: i18n.t("notifications:business.device_offline"),
    [UserNotifications.BUSINESS_PREMIUM_LIBRARY_ENABLE]: i18n.t(
        "notifications:business.premium_library_available"
    ),
    [UserNotifications.CUSTOMER_BUSINESS_CREATED]: i18n.t("notifications:business.company_created"),
    [UserNotifications.BUSINESS_LOCAL_LIBRARY_CREATED]: i18n.t(
        "notifications:business.local_library_created"
    ),
    [UserNotifications.BUSINESS_GLOBAL_LIBRARY_ENABLE]: i18n.t(
        "notifications:business.global_library_available"
    ),
    [UserNotifications.BUSINESS_PROJECT_CREATED]: i18n.t("notifications:business.project_created"),
    [UserNotifications.STUDENT_BUSINESS_CONTENT_APPROVED]: i18n.t(
        "notifications:business.content_pending"
    ),
    [UserNotifications.STUDENT_BUSINESS_PROJECT_CREATED]: i18n.t(
        "notifications:business.project_created"
    ),

    // higher ed notifications
    [UserNotifications.HIGHER_ED_ALERT_ONLINE]: i18n.t(
        "notifications:higher_ed.alert_enabled_on_screen"
    ),
    [UserNotifications.HIGHER_ED_ALERT_OFFLINE]: i18n.t(
        "notifications:higher_ed.alert_disabled_on_screen"
    ),
    [UserNotifications.HIGHER_ED_LIVE_FEED_ONLINE]: i18n.t(
        "notifications:higher_ed.live_feed_on_screen"
    ),
    [UserNotifications.HIGHER_ED_RTC_FEED_ONLINE]: i18n.t(
        "notifications:higher_ed.stream_on_screen"
    ),
    [UserNotifications.HIGHER_ED_SCREEN_CREATED]: i18n.t("notifications:higher_ed.screen_created"),
    [UserNotifications.HIGHER_ED_SCREEN_OFFLINE]: i18n.t("notifications:higher_ed.device_offline"),
    [UserNotifications.HIGHER_ED_SCREEN_BROADCASTING_ENDED]: i18n.t(
        "notifications:higher_ed.broadcast_paused"
    ),
    [UserNotifications.HIGHER_ED_SCHEDULED_CONTENT_OFFLINE]: i18n.t(
        "notifications:higher_ed.scheduled_content_expired"
    ),
    [UserNotifications.HIGHER_ED_CONTENT_EXPIRE_SOON]: i18n.t(
        "notifications:higher_ed.content_expire_soon"
    ),
    [UserNotifications.HIGHER_ED_LOCAL_LIBRARY_CREATED]: i18n.t(
        "notifications:higher_ed.local_library_created"
    ),
    [UserNotifications.HIGHER_ED_GLOBAL_LIBRARY_ENABLE]: i18n.t(
        "notifications:higher_ed.global_library_available"
    ),
    [UserNotifications.HIGHER_ED_PREMIUM_LIBRARY_ENABLE]: i18n.t(
        "notifications:higher_ed.premium_library_available"
    ),
    [UserNotifications.STUDENT_HIGHER_ED_CONTENT_APPROVAL]: i18n.t(
        "notifications:higher_ed.content_pending"
    ),
    [UserNotifications.HIGHER_ED_PROJECT_CREATED]: i18n.t(
        "notifications:higher_ed.project_created"
    ),
    [UserNotifications.HIGHER_ED_PROVIDER_CONTENT_APPROVAL]: i18n.t(
        "notifications:higher_ed.provider_content_pending"
    ),

    // translation failed notifications
    [UserNotifications.SCHOOL_SCHEDULED_TRANSLATION_FAILED]: i18n.t(
        "notifications:translation_failed.school"
    ),
    [UserNotifications.BUSINESS_SCHEDULED_TRANSLATION_FAILED]: i18n.t(
        "notifications:translation_failed.business"
    ),
    [UserNotifications.HIGHER_ED_SCHEDULED_TRANSLATION_FAILED]: i18n.t(
        "notifications:translation_failed.he"
    ),
})
