import {chunk} from "lodash"
import React, {MouseEvent, useCallback, useMemo, useRef, useState} from "react"
import {useSelector} from "react-redux"
import {useAdaptiveValues} from "scholastic-client-components/src/hooks/adaptive/useAdaptiveValues"
import {classNames} from "scholastic-client-components/src/SharedPackages/classnames"
import {authSelectors} from "scholastic-client-components/src/slices/authSlice"
import {
    getPortalsPanelData,
    getPortalsPanelItemLink,
} from "scholastic-client-components/src/types/securityTypes"
import {SmoothComponent} from "scholastic-client-components/src/ui/SmoothComponent/SmoothComponent"
import {useHomePageAdaptive} from "../../adaptive/hooks/useHomePageAdaptive"
import {PortalIcons} from "./PortalIcons"
import styles from "./styles.module.scss"
import {usePortalSectionContext} from "scholastic-client-components/src/context/portalSectionContext"
import {homeSelectors} from "../../../../slices/homeSlice"
import {useTranslation} from "react-i18next"

const PortalGap: React.FC = () => <div className={classNames(styles.portal, styles.portal_hide)} />

export const PortalList: React.FC = () => {
    const {
        i18n: {language},
    } = useTranslation()
    const {inRange, breakPoint} = useHomePageAdaptive()

    const [adaptivePortalsNumber] = useAdaptiveValues({
        floorResult: true,
        startSize: 6,
        minSize: 3,
        remBreakPoint: breakPoint,
        isMobile: inRange,
    })

    const {role, portalRoute} = usePortalSectionContext()

    const portalsPanelItems = useSelector(homeSelectors.getData)?.panelItems[role] || []
    const isBusinessUser = useSelector(authSelectors.getIsBusinessUser) || role === "business"

    const portalsInLine = inRange && adaptivePortalsNumber ? adaptivePortalsNumber : 6
    const portals = chunk(portalsPanelItems || [], portalsInLine)

    const [portalTooltip, setPortalTooltip] = useState<string>("")
    const requestId = useRef<number | null>(null)
    const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null)

    const [tooltipPos, setTooltipPos] = useState({x: 0, y: 0})

    const handleMouseMove = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
        if (!requestId.current) {
            requestId.current = requestAnimationFrame(() => {
                requestId.current = null
                setTooltipPos({x: e.clientX + 15, y: e.clientY + 20})
            })
        }
    }, [])

    const portalsPanelViewData = useMemo(getPortalsPanelData, [language])

    return (
        <>
            {portals.map((portalsGroups, index) => (
                <div className={styles.portalsLine} key={index}>
                    {portalsGroups.map((portal, index, arr) => (
                        <>
                            <a
                                href={getPortalsPanelItemLink()[portal]}
                                key={index}
                                className={styles.portalWrapper}
                                onMouseEnter={(event) => {
                                    event.persist()
                                    setTooltipPos({x: event.clientX + 15, y: event.clientY + 20})
                                    timeoutId.current = setTimeout(() => {
                                        event.target.dispatchEvent(new Event("mousemove"))
                                        setPortalTooltip(portal)
                                    }, 750)
                                }}
                                onMouseLeave={() => {
                                    setPortalTooltip("")
                                    timeoutId.current && clearTimeout(timeoutId.current)
                                }}
                                onMouseMove={(event) => {
                                    event.persist()
                                    handleMouseMove(event)
                                }}
                                data-cy={`${portal.toString().toLowerCase()}Link`}
                            >
                                <div className={styles.portal}>
                                    <div className={styles.portalIcon}>
                                        <PortalIcons
                                            portal={portal}
                                            isBusinessUser={isBusinessUser}
                                        />
                                    </div>
                                </div>
                                <div className={styles.portalName}>
                                    {portalsPanelViewData[portal].name}
                                </div>
                                <SmoothComponent show={portal === portalTooltip}>
                                    <div
                                        className={styles.tooltip}
                                        style={{
                                            left: tooltipPos.x,
                                            top: tooltipPos.y,
                                        }}
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <div>{portalsPanelViewData[portal].description}</div>
                                    </div>
                                </SmoothComponent>
                            </a>
                            {index === arr.length - 1 &&
                                arr.length < portalsInLine &&
                                new Array(portalsInLine - arr.length)
                                    .fill("")
                                    .map((_, i) => <PortalGap key={i} />)}
                        </>
                    ))}
                </div>
            ))}
        </>
    )
}
