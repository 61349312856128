import React, {FC, ReactNode, useLayoutEffect, useState} from "react"
import {createPortal} from "react-dom"

export const BodyPortal: FC<{children?: ReactNode; pointerEvents?: boolean; zIndex?: string}> = ({
    children,
    zIndex,
    pointerEvents = false,
}) => {
    const [container] = useState(() => {
        const div = document.createElement("div")
        div.style.position = "fixed"
        div.style.left = "0"
        div.style.top = "0"
        div.style.right = "0"
        div.style.zIndex = zIndex || "9999"
        div.style.pointerEvents = pointerEvents ? "unset" : "none"
        div.style.height = "100vh"

        return div
    })

    useLayoutEffect(() => {
        document.body.appendChild(container)
        return () => {
            document.body!.removeChild(container)
        }
    }, [container])

    //@ts-ignore
    return createPortal(children, container)
}
