import {Portal, PortalsPanelItem} from "../types/securityTypes"
import {GenericAPIRequest} from "../lib/axios"
import {getCallAPI} from "../lib/axios"
import axios from "axios"
import {DateFormat, TimeFormat} from "../types/userSettingsTypes"

export interface LoginRequest {
    email: string
    password: string
    code?: string
    acceptTOS?: boolean
}

export interface LoginResponse {
    request?: {
        email: string
        password: string
    }
    email: string
    phone?: string
    authorities: string[]
    roleAuthorities: {[p in keyof AuthPerson["roleAuthorities"]]?: string[]}
    id: number
    access_token: string
    nonConfirmedAccount?: boolean
    request2fa?: boolean
    requestTOSAgreement?: boolean
    SSOLink?: string
}

const login =
    <RootState>(): GenericAPIRequest<
        RootState,
        LoginRequest & {browserId: string},
        LoginResponse
    > =>
    ({payload: {code, acceptTOS, browserId, ...payload} = {}, ...params}) => {
        return getCallAPI<RootState>()({
            url: "/api/login",
            config: {headers: {"tfa-code": code, "accept-tos": acceptTOS, browserId}},
            payload,
            ...params,
        })
    }

export interface LogoutRequest {
    access_token?: string
}

const logout =
    <RootState>(): GenericAPIRequest<RootState, LogoutRequest> =>
    (params) => {
        return getCallAPI<RootState>()({url: "/api/logout", ...params})
    }

export type ResetRequest = Pick<LoginRequest, "email">

const reset =
    <RootState>(): GenericAPIRequest<RootState, ResetRequest> =>
    (params) => {
        return getCallAPI<RootState>()({url: "api/person/sendResetPassword", ...params})
    }

export interface AuthPerson {
    id: number
    email: string
    authorities: string[]
    roleAuthorities: {
        school?: string[]
        business?: string[]
        higherEd?: string[]
    }
    fullName: string
    portals: Portal[]
    portalsPanelItems?: PortalsPanelItem[]
    school?: number
    district?: number
    businessName?: string
    alertusToken?: string
    isShowCertificates?: boolean
    isStudentUser?: boolean
    dateFormat: DateFormat
    timeFormat: TimeFormat
}

export interface AuthResponse {
    token: string
    person: AuthPerson
}

const auth =
    <RootState>(): GenericAPIRequest<RootState, null, AuthResponse> =>
    (params) => {
        return getCallAPI<RootState>()({url: "/api/auth", ...params})
    }

interface SavePasswordRequest {
    token: string
    password: string
}

const savePassword =
    <RootState>(): GenericAPIRequest<RootState, SavePasswordRequest> =>
    (params) => {
        return getCallAPI<RootState>()({url: "/api/person/resetPassword", ...params})
    }

export const getAuthAPI = <RootState>() => ({
    login: login<RootState>(),
    logout: logout<RootState>(),
    reset: reset<RootState>(),
    auth: auth<RootState>(),
    savePassword: savePassword<RootState>(),
})

export const axiosInstance = axios
